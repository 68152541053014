<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">正在初始化数据，请稍等...</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { wxworkInstallApp } from "@/api/user";
import { currentHost } from "@/utils/request.js";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      code: route.query.auth_code || "",
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      //通过code获取用户的基本信息
      wxworkInstallApp({ auth_code: state.code, site: currentHost }).then((res) => {
        if (res.ret == 0) {
          successCallback(res, state);
          store.dispatch("setPlatform", "wxwork");
        }
      });
    };

    return {};
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>